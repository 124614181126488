import "../style.scss";
import { Link, useNavigate, useLocation} from "react-router-dom";
import React, {useState} from "react";
import Logger from "../utils/logger";
import {usePsyfyClient} from "../hooks/psyfyClient";

const Header: React.FC<HeaderProps> = ({ isChatbotPage = false }) => {
  const navigate = useNavigate();
  const location = useLocation();


  const isLoggedIn = () => !!localStorage.getItem("userEmail");
  const {  requestSummarySurrogate} = usePsyfyClient();

  // Function to scroll to the top of the page
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  const callSummaryBackend = async () => {
    // Log the action and call the backend for a summary
    Logger.debug("Calling Summary Backend...");
    try {
      await requestSummarySurrogate();
      Logger.debug("Summary requested successfully.");
    } catch (error) {
      Logger.error("Error requesting summary", error);
    }
  };
  

  //const handleLogout = () => {
  const handleLogout = async () => {

    if (isChatbotPage) {
      await callSummaryBackend();
    }
    localStorage.clear();
    sessionStorage.clear();

    alert("You have now logged out of the system.");

    //Redirect after logout
    navigate("/");
  };
  const navbarStyle = {
    backgroundColor: isChatbotPage ? "#EF7B45" : "#fafafa",
  };

   // Check if current path is /chatbot
   const isChatbotRoute = location.pathname === '/chatbot';

  return (
    <nav className="navbar" style={navbarStyle}>
      <div className="collapse navbar-collapse" id="navbarSupportedContent">
        <ul className="navbar-nav ml-auto">
          <li className="nav-item">
            <Link className="nav-link" to="/" onClick={scrollToTop}>
              Main
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to="/intro" onClick={scrollToTop}>
              Intro
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to="/research" onClick={scrollToTop}>
              Research
            </Link>
          </li>

          {/* profile only shows after login */}
          {isLoggedIn() && (
            <li className="nav-item">
              <Link className="nav-link" to="/profile" onClick={scrollToTop}>
                Profile
              </Link>
            </li>
          )}

          {!isLoggedIn() && (
            <li className="nav-item">
              <Link className="nav-link" to="/signup" onClick={scrollToTop}>
                Sign Up
              </Link>
            </li>
          )}

          {!isLoggedIn() && (
            <li className="nav-item">
              <Link className="nav-link" to="/login">
                Login
              </Link>
            </li>
          )}

          {isLoggedIn() && (
            <li className="nav-item">
              <button onClick={handleLogout} className="nav-link">
                Logout
              </button>
            </li>
          )}

         {isLoggedIn() && (
            <li className="nav-item">
              <Link className="nav-link" to="/credits">
                Subscription
              </Link>
            </li>
          )}

          {!isChatbotRoute && (
            <li className="nav-item">
              <Link className="nav-link" to="/chatbot" onClick={scrollToTop}>
                Chat with Psyfy
              </Link>
            </li>
          )}
        </ul>
      </div>
    </nav>
  );
};

export default Header;
