import React, { useState } from "react";
import { Link } from "react-router-dom";
import { sendAgreeData } from "../../hooks/chatbotService";
import Logger from "../../utils/logger"


const AgreementComponent = ({ onAgree }) => {
  const [dataConsent, setDataConsent] = useState(true);
  const [agreeToChat, setAgreeToChat] = useState(false); 


  const handleDataConsentChange = async (e) => {
    const isChecked = e.target.checked;
    setDataConsent(isChecked);

    try {
      await sendAgreeData(isChecked);
      Logger.debug("Data consent sent to the backend:", isChecked);
    } catch (error) {
      Logger.error("Error sending data to the backend:", error);
    }
  };



  const handleAgree = async () => {
    setAgreeToChat(true); 

    try {
      // This should be valid because handleAgree is an async function
      await sendAgreeData(dataConsent,  true);
      Logger.debug("Data consent sent to the backend:", dataConsent,  true);

      // If sending the data consent is successful
      onAgree();
    } catch (error) {
      Logger.error("Error sending data to the backend:", error);
      // Optionally handle the error, perhaps show a message to the user
    }
  };

  return (
    <div className="agree-tacbox" id="tacbox1">
      <label htmlFor="button1">
        <h2 style={{ textAlign: "center" }}>Welcome to Psyfy!</h2>
        <br></br>
        <p>
          We're excited to have you here. Before we begin, we kindly ask you to
          review our <Link to="/terms">terms and conditions</Link> if you
          haven't already done so.
        </p>{" "}
        <br></br>
        <p>By clicking "Agree," you acknowledge that:</p> <br></br>
        <ul className="indented-list">
          <li>
            The AI may generate a wide range of responses, including some that
            could be potentially sensitive or controversial.
          </li>
          <br></br>
          <li>
            You understand and agree that this website and its creators cannot
            be held responsible for the AI's statements.
          </li>

          <br></br>
          <li>
            We strive to protect your data through encryption but cannot
            guarantee its absolute security. Rest assured, we do not sell your
            information to third parties.
          </li>
        </ul>
        <br></br>
        <div className="checkbox-container">
          {/* <input type="checkbox" id="data-consent" /> */}
          <input
            type="checkbox"
            id="data-consent"
            checked={dataConsent}
            onChange={handleDataConsentChange}
          />
          <label htmlFor="data-consent">
            I would like to contribute my data to improving Psyfy's service.
            Review our <Link to="/privacy">Privacy Policy</Link>
          </label>
        </div>
        <div style={{ height: "2vh" }}></div>
      </label>

      <div className="agree-btn-container">
        <button onClick={handleAgree} className="agree-btn">
          Agree
        </button>
      </div>
    </div>
  );
};

export default AgreementComponent;
