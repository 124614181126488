import React, { useEffect } from 'react';
import IntroductionText from "./IntroductionText";
import Header from "./Header";
import FrontPageFooter from "./FrontPageFooter";
import "../css/intro_style.scss";
import LuciaImage from "../images/Lucia.jpeg";
import BenImage from "../images/Ben.jpg";
import DavidImage from "../images/David.jpeg";
import PilleriinImage from "../images/Pilleriin.jpg";

interface MemberInfoProps {
  image: string;
  name: string;
  title: string;
  bio: string;
}

const MemberInfo: React.FC<MemberInfoProps> = ({ image, name, title, bio }) => {
  useEffect(() => {
    
    document.body.classList.add("research-page-body");


    return () => {
      document.body.classList.remove("research-page-body");
    };
  }, []);  

  return (
    <div className="research-subheading">
      <img src={image} alt={name} />
      <h3>{name}</h3>
      <h4>{title}</h4>
      <p>{bio}</p>
    </div>
  );
};

const Intro: React.FC = () => {
  return (
    <>
 
    <div className="header-intro">
      <Header />
    </div>
  
   
  
      <div className="research-style" id="MainTable">
        <IntroductionText
          title="OUR CORE MEMBERS"
          content={
            <>
              <MemberInfo
                image={LuciaImage}
                name="Lucia Chen, Ph.D. in Computer Science"
                title="B.A. Economics, M.A. Applied Psychology, Ph.D. Computer Science"
                bio="Lucia's PhD work focuses on using NLP for analyzing mental health on social media. After completing her postdoctoral scholar program at Stanford University, where she focused on machine learning fairness, Lucia's commited to creating equitable technology solutions. Psyfy is also a reflection of Lucia's dedication to integrating interdisciplinary insights to foster innovation in mental health care."
              />
              <br></br> <br></br> <br></br> <br></br>
              <MemberInfo
                image={BenImage}
                name="Ben Krause, Ph.D. in Computer Science"
                title="B.A. Psychology, Ph.D Machine Learning"
                bio="Ben is a specialist in generative language modeling who has been in the field for 8 years. He has developed and published multiple methods that improved the state of the art in language modeling, including the multiplicative LSTM architecture that was used by OpenAI in their early work on LLMs. He is also a former Senior Research Scientist at Salesforce where he designed a generative AI used by Gucci customer advisors to improve their sales conversation rates."
              />
              <br></br> <br></br> <br></br>
              <MemberInfo
                image={DavidImage}
                name="David Preece, Ph.D. M.ClinPsych"
                title="Ph.D. Clinical Psychology"
                bio="David is a Clinical Psychologist and the Director of the Perth Emotion & Psychopathology Lab at Curtin University. His main research and practice interests are in the assessment, understanding, and treatment of emotional disorders and problems (e.g., depression, anxiety, loneliness). Much of his research focuses on the key role that emotion regulation difficulties play in a variety of mental health issues. David's work explores how cognitive behavior therapy can be used to improve people's emotion regulation skills, and in turn, improve people's well-being across a range of areas."
              />
            </>
          }
        />
        <br></br> <br></br> <br></br> <br></br>
        <IntroductionText
          title="OUR RESEARCH"
          content={
            <>
              <p style={{ marginBottom: "20px" }}>
                Psyfy is a large language model chatbot that provide self-help
                guidance to people with mental health issues. Our core
                principles are user safety and product trustworthiness and we
                take every measure to ensure that Psyfy's responses do not cause
                stress to our users. Before Psyfy's market entry, we commit to
                performing an exhaustive assessment and sharing our results.
              </p>

              <p style={{ marginBottom: "20px" }}>
                Our team is heavily engaged in comprehensive research projects.
                Our primary focus includes proposing an innovative framework to
                study the safety and information trustworthiness of mental
                wellness chatbots. We also plan to adjust our model to be
                equitable for users from various cultural backgrounds and
                investigate the effectiveness of Psyfy in mitigating anxiety and
                depressive symptoms. We welcome any potential collaboration
                interests and encourage you to reach out to us for involvement
                in our enlightening research projects.
              </p>

              <p>
              We are excited to announce our latest research publication on arXiv, titled <a href="https://arxiv.org/pdf/2407.11387" target="_blank">A Framework for Evaluating Appropriateness, Trustworthiness, and Safety in Mental Wellness AI Chatbots</a>.  This new paper outlines the comprehensive framework we've developed to assess our mental wellness chatbots. David Preece and Lucia Chen co-designing the<a href="http://www.psyfy.ai/Guideline" target="_blank"> Data Annotation Guidelines</a> to ensure the standards of data quality and relevance.
              </p>

            
            </>
          }
        />
        <IntroductionText
          title="RESEARCH MEMBERS"
          content={
            <>
              <p>
                The research of Psyfy involves the participation of our core
                members, as well as additional researchers who collaborate with
                us to conduct experiments on Psyfy.
              </p>{" "}
              <br></br>
              <br></br>
              <MemberInfo
                image={PilleriinImage}
                name="Pilleriin Sikka, PhD in Psychology"
                title="B.Sc. and M.Sc. in Psychology, M.Sc. in Neuroscience."
                bio="Pilleriin is currently a Postdoctoral Research Fellow at the Department of Psychology, Stanford University, and a Senior Lecturer in Cognitive Neuroscience at the Department of Cognitive Neuroscience and Philosophy, University of Skovde (Sweden). She conducts research on emotions and emotion regulation, and how these are related to health and well-being."
              />
            </>
          }
        />
      
      <div className="front-page-footer">
        <FrontPageFooter />
      </div>
    

      </div>
   

     
     

      </>

  );
};

export default Intro;
