import { useState, useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
export const useChatbotMessages = (initialMessages) => {
  const [messages, setMessages] = useState(initialMessages);

  useEffect(() => {
    sessionStorage.setItem("chatMessages", JSON.stringify(messages));
  }, [messages]);

  const addMessage = (
    authorType,
    text,
    stateInfo,
    nodeType = {}
    // messageId
  ) => {
    // const timestamp = new Date().toLocaleTimeString();
    const timestamp = new Date().toLocaleString("en-US", {
      year: "numeric",
      month: "2-digit",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: false,
    });
    const newMessage = {
      id: uuidv4(),
      author: authorType === "bot" ? "Mia" : " ",
      text,
      timestamp,
      type: authorType,
      stateInfo,
      nodeType,
      // messageId,
    };

    setMessages((current) => [...current, newMessage]);
  };

  // Define the updateMessages function to allow updating the entire messages array
  const updateMessages = (updatedMessages) => {
    setMessages(updatedMessages);
  };

  return [messages, addMessage, updateMessages];
};
