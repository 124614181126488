import React, { useState, useEffect, useRef, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { io } from "socket.io-client";
import { v4 as uuidv4 } from "uuid";
import CONFIG from "../../config";
import "../../css/style_chatbot.scss";

// Import custom hooks and utility components.
import { useChatbotMessages } from "../../hooks/useChatbotMessage";
import { useUserAgreement } from "../../hooks/useUserAgreement";
import {usePsyfyClient} from "../../hooks/psyfyClient";
import Logger from "../../utils/logger";

// Import UI components.
import MessageList from "./MessageList";
import MessageInputArea from "./MessageInputArea";
import AgreementComponent from "./AgreementComponent";
import Header from "../Header";
import {fetchUserAgreementStatus} from "../../hooks/chatbotService"
import debounce from "lodash.debounce"; 

function LoadingModal({ isOpen }) {
  if (!isOpen) return null;

  return (
    <div className="modal-backdrop">
      <div className="modal-content">
        <h2>Psyfy is preparing for the conversation...</h2>
      </div>
    </div>
  );
}


function Chatbot() {
  const socketRef = useRef(); // Reference to the WebSocket connection.
  const { checkAndSyncSession, fetchConversationHistory, prepareAndSendBotMessage, sendRating, requestSummarySurrogate, fetchUserData, checkIfRepeatUser, prepareAndSendBotMessageInitial, handleLanguageChange} = usePsyfyClient();
   
  // Custom hook to manage chat messages.
  const [messages, addMessage, updateMessages] = useChatbotMessages(
    JSON.parse(sessionStorage.getItem("chatMessages") || "[]")
  ); //author, message_id, text, timestamp, type, stateinfo, nodetype
  
    // State management for UI elements.
  const [sessionId, setSessionId] = useState(
    sessionStorage.getItem("uuid") || false
  );

  const [hasAgreed, setHasAgreed] = useUserAgreement(
    sessionStorage.getItem("hasAgreed") === "true"
  );

  const [showSessionCompleteButton, setShowSessionCompleteButton] =
    useState(false);

  const [showAgreement, setShowAgreement] = useState(false); 

  const [isAwaitingBotResponse, setIsAwaitingBotResponse] = useState(false);
  const userEmail = localStorage.getItem("userEmail");

  const navigate = useNavigate();

  const [compactMode, setCompactMode] = useState(() => {
    // Correcting to check for compactMode instead of darkMode
    return localStorage.getItem("compactMode") === "true";
  });

  //check if user is on waitlist
  const [isOnWaitlist, setIsOnWaitlist] = useState(false); 
  //const { isOnWaitlist } = useWaitlistStatus(userEmail);
  const [isRepeatUser, setIsRepeatUser] = useState(false);
  const [hasCredits, setHasCredits] = useState(false);
  const [initialMessageSent, setInitialMessageSent] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [promptOnUnload, setPromptOnUnload] = useState(false);
  const [language, setLanguage] = useState('English');  
  const [firstBotMessageReceived, setFirstBotMessageReceived] = useState(false);
  const [isTyping, setIsTyping] = useState(false);
  
   // Wrap the imported function to include setting the local state
   const onLanguageChange = (selectedLanguage) => {
    handleLanguageChange(selectedLanguage, setLanguage);
  };

  
   // State to track if conversation is synced.
  const [isConversationSynced, setIsConversationSynced] = useState(
    sessionStorage.getItem("isConversationSynced") === "true"
  );
  
  // References to track certain elements in the DOM.
  const endOfMessagesRef = useRef(null);
  const endOfContentRef = useRef(null); // New ref for the session complete buttons.
  const userStatusRef = useRef({ hasCredits, isRepeatUser, isOnWaitlist });
  const [isSessionChecked, setIsSessionChecked] = useState(false);
  const [uiStyle, setUiStyle] = useState('style1'); 


// Control the prompt based on loading state, user cannot refresh the page when memory is loading
useEffect(() => {
  if (isLoading) {
    setPromptOnUnload(true);
  } else {
    setPromptOnUnload(false);
  }

}, [isLoading]);


  // Fetch user data on load using userEmail.
  useEffect(() => {
    const userEmail = localStorage.getItem("userEmail");
    if (userEmail) {
      fetchUserData(userEmail)
        .then(data => {
         
          setIsOnWaitlist(data.isOnWaitlist);
          setIsRepeatUser(data.isRepeatUser);
          setHasCredits(data.hasCredits);
          Logger.debug("user info", data)
       
        })
        .catch(error => {
          Logger.debug("Failed to fetch user data:", error);
        });
    }
  }, []);

   // Update user status ref whenever related states change. Check whether user has credits, is a repeat user or on a waitlist
  useEffect(() => {
    userStatusRef.current = {
        hasCredits: hasCredits,
        isRepeatUser: isRepeatUser,
        isOnWaitlist: isOnWaitlist
    };
  

}, [hasCredits, isRepeatUser, isOnWaitlist]);



  
  // Handle WebSocket connection and setup.
  // useEffect(() => {
  //   socketRef.current = io(`${CONFIG.BASE_URL}/chat`, {
  //     transports: ["websocket"],
  //     withCredentials: true,
  //   });

  //   Logger.debug("WebSocket connected", socketRef.current);

  //   return () => {
  //     socketRef.current.disconnect();
  //   };
  // }, []);

  useEffect(() => {
    // Initialize the WebSocket connection
    socketRef.current = io(`${CONFIG.BASE_URL}/chat`, {
        transports: ["websocket"],
        withCredentials: true,
        reconnection: true,
        reconnectionAttempts: Infinity,
        reconnectionDelay: 1000,
        reconnectionDelayMax: 5000,
        randomizationFactor: 0.5
    });
  
    // Handle connection
    socketRef.current.on('connect', () => {
        Logger.debug("WebSocket connected", socketRef.current.id);
    });
  
      // Handle disconnections
      socketRef.current.on('disconnect', (reason) => {
        Logger.debug("WebSocket disconnected", reason);
        if (reason !== 'io client disconnect') {  // Avoid reconnection on client-initiated disconnects
            socketRef.current.connect();  // Attempt to reconnect manually
        }
    });
  
    // Handle connection errors
    socketRef.current.on('connect_error', (error) => {
        Logger.debug("Connection Error", error);
    });
  
    // Cleanup function to clear interval and disconnect socket when component unmounts
    return () => {

        if (socketRef.current) {
            socketRef.current.disconnect();
            Logger.debug("WebSocket disconnected on component unmount");
        }
    };
  }, []);
    

 //handle user agreement
 //first time user will see this agreement tab
 const handleAgree = () => {
  sessionStorage.setItem("hasAgreed", "true");

  setHasAgreed(true);
  setShowAgreement(false);
};



  //create a session id when the window is open
  useEffect(() => {
    if (!sessionStorage.getItem("uuid")) {
      const newSessionId = uuidv4();
      sessionStorage.setItem("uuid", newSessionId);
      setSessionId(newSessionId);
    
    }
  }, []);


  useEffect(() => {

    const handleSessionCheck = async () => {

      const userEmail = localStorage.getItem("userEmail");
      if (!userEmail) {
        navigate("/login");
        return;
      }
      
      
      if (userEmail) {
        //check if user has chatbot agreement
        fetchUserAgreementStatus(userEmail).then(data => {
          setHasAgreed(data.hasAgreed);
          setShowAgreement(false);
          setIsRepeatUser(true);
          sessionStorage.setItem("hasAgreed", data.hasAgreed.toString()); 
          
          if(data.hasAgreed === false){
            setShowAgreement(true);
            setIsRepeatUser(false);
          }
    
      }).catch(error => {
        Logger.error("Error while fetching user status:", error);
        setShowAgreement(true); // Fallback to showing agreement in case of error
      });
    }
    
     
   
      const hasSynced = sessionStorage.getItem("hasSyncedConversation") === "true";

      const chatMessagesJSON = sessionStorage.getItem("chatMessages");
      const chatMessages = chatMessagesJSON ? JSON.parse(chatMessagesJSON) : [];
      const messagesExist = chatMessages.length > 0;
      const storedHasAgreed = sessionStorage.getItem("hasAgreed");

      if (storedHasAgreed && !messagesExist && !hasSynced) {

        const initialSessionId = sessionStorage.getItem("uuid")
   
      // const sessionData = await checkAndSyncSession(userEmail, sessionId);
      const sessionData = await checkAndSyncSession(userEmail, initialSessionId);
  
  


        //if sessionData exist, set the old session id as the current one, load conversation 
        if (sessionData.session_id) {


          setIsLoading(true); 
         // await callSummaryBackend()

       
          // Check if there's a valid session ID from the sync response
          setSessionId(sessionData.session_id);
          setHasAgreed(true);
          socketRef.current.emit("join_session", { sessionId: sessionData.session_id });
      

 
          await syncConversation(sessionData.session_id);

          sessionStorage.setItem("hasSyncedConversation", "true");
          sessionStorage.setItem("uuid", sessionData.session_id);
          setIsLoading(false); 
        //if session data doesnt exist, print the initial message
        } else if (!sessionData.session_id) {

        

      
          // If there's no session id and no previous session data, provide the initial message
          const messageIndex = uuidv4();
          const botResponseData = await prepareAndSendBotMessageInitial(messageIndex);
  
          const botResponse = botResponseData.reply;
    
          
          if (botResponse && !initialMessageSent) {
         
            addMessage("bot", botResponse);
            sessionStorage.setItem("chatMessages", JSON.stringify([botResponse])); // Ensure it's an array
            setInitialMessageSent(true); 
         
   
         
           
          } else {
            Logger.error("Failed to provide initial message.");
          }
        }
      }
    };

    if (!isSessionChecked) {
      handleSessionCheck();
      setIsSessionChecked(true);
    }

  }, [hasAgreed, isSessionChecked]);


  // Handle receiving messages from the socket
  useEffect(() => {
    if (!socketRef.current) return;

    socketRef.current.on("receive_message", (messageInfo) => {
      Logger.debug("using websocket..........", messageInfo);




      //these are the displayed messages
      const messageAlreadyDisplayed = messages.some((msg) => {
        return msg.id === messageInfo.messageId; // Return the comparison result
      });

      //check if messages are already displayed. only show the messages that are not displayed
      if (!messageAlreadyDisplayed && messageInfo.sessionId === sessionId) {
        addMessage(
          messageInfo.type,
          messageInfo.text,
          null,
          messageInfo.messageId
        );
      }
    });

    return () => {
      socketRef.current.off("receive_message");
    };
  }, [sessionId, messages, addMessage]); 

  //pull conversation with session id
  async function syncConversation(sessionId) {


    if (!sessionId) {
      Logger.debug("No session ID provided for syncing conversation.");
      return;
    }

    try {
   
      const data = await fetchConversationHistory(sessionId);

  
     
  
      if (data && data.messages) {
        // Limit the number of messages to the last 200 entries
        const limitedMessages = data.messages.slice(-200);

 
        limitedMessages.forEach((savedMessage) => {
          addMessage(
            savedMessage.type === "bot" ? "bot" : "user", // Correctly set the message source based on the saved data
            savedMessage.text,
            null,
            savedMessage.timestamp,
            savedMessage.author
          );
        });

        
  

       // Check if any message contains non-empty text
        const hasTextualContent = data.messages.some(message => message.text && message.text.trim().length > 0);

        // Only set "isConversationSynced" if there are actual text messages
        if (hasTextualContent) {
          sessionStorage.setItem("isConversationSynced", "true");
          setIsConversationSynced(true);
        } else {
          sessionStorage.setItem("isConversationSynced", "false");
          setIsConversationSynced(false);
        }
      

        sessionStorage.setItem("chatMessages", JSON.stringify(data.messages));

        if (!userStatusRef.current.hasCredits) {
          addMessage(
            "bot",
            "You are out of credits, please purchase more credits at https://psyfy.ai/credits"
          );
        }

      } else {
        Logger.debug("No messages found for the given session ID.");
      }
    } catch (error) {
      Logger.debug(error.message); // Error now contains the message thrown by fetchConversationHistory
    }
  }

  //helper function: toggle display mode
  const toggleCompactMode = () => {
    setCompactMode(!compactMode);
    localStorage.setItem("compactMode", !compactMode);
  };


  // const onStyleChange = (newStyle) => {
  //   setUiStyle(newStyle);

  //   console.log(getComputedStyle(document.documentElement).getPropertyValue('--left-msg-bg'));
  
  //   let leftMsgBg, rightMsgBg;
  //   switch (newStyle) {
  //     case 'style1':
  //       leftMsgBg = 'radial-gradient(circle at bottom left, #f4edd6, #e5e5e5)';
  //       rightMsgBg = 'radial-gradient(circle at bottom left, #593f41, #b17464)';
  //       console.log('Style 1 selected', leftMsgBg);
  //       // background = 'url("../../assets/chatbot_bg1.jpg")'; 
  //       break;
  //     case 'style2':
  //       leftMsgBg = 'radial-gradient(circle at bottom left,  #ebeed5, #f8d8da)';
  //       rightMsgBg= 'radial-gradient(circle at bottom left, #874CCC, #667BC6)';
  //       console.log('Style 2 selected', leftMsgBg);
  //       // background = 'radial-gradient(circle at bottom left, #ebeed5, #f8d8da)';
  //       // background = 'url("../../assets/bedroom.jpg")'; 
  //       // break;

  //     default:
  //     leftMsgBg = 'radial-gradient(circle at bottom left, #f4edd6, #e5e5e5)'; // Default style for left
  //     rightMsgBg = 'radial-gradient(circle at bottom left,  #593f41, #b17464)'; 
  //     // background = 'url("../../assets/chatbot_bg1.jpg")'; 

  //   }
  
  //   // Update the CSS property based on the selected style
  //   document.documentElement.style.setProperty('--left-msg-bg', leftMsgBg);
  //   document.documentElement.style.setProperty('--right-msg-bg', rightMsgBg);
  //   // document.documentElement.style.setProperty('--chatbot-background', background);
  //   console.log('CSS Variables set:', getComputedStyle(document.documentElement).getPropertyValue('--left-msg-bg'));
  
  //   // Add more style changes here if necessary, such as changing the background image, text color, etc.
  // };

  //generate a summary when user leave chat
  useEffect(() => {
  

    const handleBeforeUnload = (event) => {
      // Set a flag or timestamp to indicate the page is undergoing unload events
      // Example with timestamp

      if (promptOnUnload) {
        // Prevent the page from being closed
        event.preventDefault();
        event.returnValue = 'Changes you made may not be saved.';
      }
  

      sessionStorage.setItem("unloadTimestamp", Date.now().toString());

      // Preventing default and setting returnValue to trigger the dialog
      // Note: Custom messages are not supported in modern browsers
      event.preventDefault();
      event.returnValue =
        "You are about to exit the chat, are you sure you want to continue?";
    };

    const handleUnload = async (event) => {
      // Check if a short duration has passed since `beforeunload`,
      // which might indicate it's more likely a tab close than a refresh
      const unloadTimestamp = Number(sessionStorage.getItem("unloadTimestamp"));
      if (Date.now() - unloadTimestamp < 1000) {
        // 1 second threshold
        await callSummaryBackend();

      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
    window.addEventListener("unload", handleUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
      window.removeEventListener("unload", handleUnload);
      sessionStorage.removeItem("unloadTimestamp"); // Cleanup
    };
  }, [requestSummarySurrogate, promptOnUnload]); // Empty array ensures this effect runs once on mount





//if user idle 30 mins, show reset conversation button 
//if user idle 12 hrs, call summary, then start the conversation
  useEffect(() => {
    let thirtyMinuteTimeoutId;
    let twelveHourTimeoutId;
  
    const handleUserAction = () => {
      // Clear any existing timeouts to reset the timers
      clearTimeout(thirtyMinuteTimeoutId);
      clearTimeout(twelveHourTimeoutId);
  
      // Set up a new timeout that triggers after 30 minutes of inactivity
      thirtyMinuteTimeoutId = setTimeout(() => {
        // Here you can set a state indicating the session is complete
        setShowSessionCompleteButton(true);
        Logger.info(
          "User has been inactive for 30 minutes. Session will be completed soon."
        );
      }, 28800000); // 1,800,000 milliseconds = 30 minutes  28800000
  
      // Set up another timeout for 12-hour inactivity actions
      twelveHourTimeoutId = setTimeout(async () => {
        // Clear chat messages from session storage
        sessionStorage.removeItem("chatMessages");
  
        // Reset the chat messages in the state
        updateMessages([]);
  
        try {

          await callSummaryBackend();

          const messageIndex = uuidv4();
          setIsAwaitingBotResponse(true);
          const botResponseData = await prepareAndSendBotMessageInitial(messageIndex);
          if (botResponseData && botResponseData.reply) {
  
            const decryptedData = botResponseData.reply;
            addMessage("bot", decryptedData); // Assuming decryptedData is the actual message text
            sessionStorage.setItem("chatMessages", JSON.stringify([{ type: "bot", text: decryptedData }]));
            setIsAwaitingBotResponse(false);

      
          } else {
            Logger.error("Failed to fetch initial message after inactivity.");
            // Fallback message if no data is returned or in case of an error
            addMessage("bot", "Welcome back! How have you been since our last session? 😊");
          }
        } catch (error) {
          Logger.error("Error in fetching initial message:", error);
          // Fallback message in case of exception
          addMessage("bot", "Welcome back! How have you been since our last session? 😊");
        }

      }, 43200000); // 43,200,000 milliseconds = 12 hours
    };
  
    // Add event listeners for user actions to reset the timer
    window.addEventListener("mousemove", handleUserAction);
    window.addEventListener("keydown", handleUserAction);
  
    // Clean up function in the return statement
    return () => {
      // Remove the event listeners and clear the timeouts
      window.removeEventListener("mousemove", handleUserAction);
      window.removeEventListener("keydown", handleUserAction);
      clearTimeout(thirtyMinuteTimeoutId);
      clearTimeout(twelveHourTimeoutId);
    };
  }, [
    setShowSessionCompleteButton,
    updateMessages,
    addMessage,
    requestSummarySurrogate,
    prepareAndSendBotMessageInitial,
 
  ]);

  //user click clear session bustton
  const callSummaryBackend = async () => {
    // Log the action and call the backend for a summary
    Logger.debug("Calling Summary Backend...");
    try {
      await requestSummarySurrogate();
      Logger.debug("Summary requested successfully.");
    } catch (error) {
      Logger.debug("Error requesting summary", error);
    }
  };
  
  const clearSessionMessages = async () => {
    // Confirm with the user before clearing the session
    if (window.confirm("Are you sure you want to clear this session's messages?")) {
      // Call the summary backend function right after confirmation
      setIsLoading(true); 

      await callSummaryBackend();
     
  
      // Clear the session messages
      updateMessages([]);
      sessionStorage.removeItem("chatMessages");
  
      try {
        const messageIndex = uuidv4();
        const botResponseData = await prepareAndSendBotMessageInitial(messageIndex);
        if (botResponseData && botResponseData.reply) {
          const decryptedData = botResponseData.reply


          addMessage("bot", decryptedData);
          sessionStorage.setItem("chatMessages", JSON.stringify([{ type: "bot", text: decryptedData }]));
        } else {
          Logger.error("Failed to fetch initial message after session clear.");
          addMessage("bot", "Welcome back! How have you been since our last session? 😊");
        }
      } catch (error) {
        Logger.error("Error in fetching initial message after session clear:", error);
        addMessage("bot", "Welcome back! How have you been since our last session? 😊");
      }
  
      // Hide the session complete button
      setIsLoading(false);
      setShowSessionCompleteButton(false);
    }
  };

 
  //handle showing the reset conversation button
  const handleContinueConversation = () => {
    // Logic to continue the conversation
    // Assuming you have some functionality here
    setShowSessionCompleteButton(false); // Hide both buttons
  };

  //logic for scrolling down
  useEffect(() => {
    const scrollToBottom = () => {
      endOfMessagesRef.current?.scrollIntoView({ behavior: "smooth" });
      endOfContentRef.current?.scrollIntoView({ behavior: "smooth" });
    };

    // Execute the scroll only after the DOM has been updated
    if (showSessionCompleteButton) {
      setTimeout(scrollToBottom, 0);
    } else {
      scrollToBottom();
    }
  }, [messages, showSessionCompleteButton]);

  //reference the last message or a dummy `div` at the end of a list of messages. The purpose of this could be to manage scrolling behavior, particularly to automatically scroll to the bottom of the chat when a new message is added.

  const handleMessageSubmit = useCallback(
    async (messageText) => {
      // Trim messageText and check it is not empty
      const trimmedMessageText = messageText.trim();
      if (!trimmedMessageText) {
        // If message text is empty or only contains whitespace, don't proceed
        Logger.debug("Empty message, not adding to chat.");
        return;
      }

      if (!isAwaitingBotResponse) {
        setIsAwaitingBotResponse(true);
        setIsTyping(true); 
      

        //save all messages in sessionstorage
        const updatedMessages = [
          ...messages,
          { type: "user", text: trimmedMessageText },
        ];
        sessionStorage.setItem("chatMessages", JSON.stringify(updatedMessages));

        //generate timestamp for human message
        const humanTimestamp = new Date().toISOString();

        // Emit the user's message with correct details
        socketRef.current.emit("send_message", {
          type: "user", // Important: Specify this message is from the user
          text: trimmedMessageText,
          messageId: uuidv4(), //this message id is sent to /chat and later is used to check if the id overlapped with the displayed messages
          sessionId,
          humanTimestamp,
        });

        try {
          const messageIndex = uuidv4();
          if (messages.length === 0 || messages[messages.length - 1].author !== "bot") {
            const botResponseData = await prepareAndSendBotMessage(
              messages,
              messageText,
              messageIndex,
              humanTimestamp
            );
  
            let botResponse = botResponseData.reply;
            let botMessages = botResponse.split(/\n\s*\n/); // Split the response by double line breaks
  
            // Handle each message part with a delay
            botMessages.forEach((part, index) => {
              setTimeout(() => {
                const trimmedPart = part.trim();
                const partMessageId = uuidv4();
                socketRef.current.emit("send_message", {
                  type: "bot",
                  text: trimmedPart,
                  messageId: partMessageId,
                  sessionId,
                });
              }, index * 1500); // Delay each message by 1.5 seconds more than the last
            });
  
            // Reset isAwaitingBotResponse after the last message is shown
            const totalDelay = 500 * botMessages.length;
            setTimeout(() => {
              setIsAwaitingBotResponse(false);
              setIsTyping(false);
            }, totalDelay);
          }
        } catch (error) {
          Logger.debug("Error sending message to bot:", error);
          const errorMessage =
            "Your last message was not received. Please verify your credit balance on the subscription page or try logging out and logging back in, as your session may have expired.";
          addMessage("bot", errorMessage);
          setIsAwaitingBotResponse(false);
          setIsTyping(false);
        }
      }
    },
    [addMessage, messages, isAwaitingBotResponse, prepareAndSendBotMessage, setIsAwaitingBotResponse, setIsTyping, socketRef.current]
  );

  //retrieve rating from form and send it back to the server

  const handleRating = async (ratingType, messageId) => {
    let localUpdatedMessages = [...messages];
    localUpdatedMessages = localUpdatedMessages.map((message) =>
      message.id === messageId
        ? {
            ...message,
            ratingUp:
              ratingType === "up" ? !message.ratingUp : message.ratingUp,
            ratingDown:
              ratingType === "down" ? !message.ratingDown : message.ratingDown,
          }
        : message
    );

    sessionStorage.setItem(
      "chatMessages",
      JSON.stringify(localUpdatedMessages)
    );
    updateMessages(localUpdatedMessages); //updates the state

    //  handling the API call with updated local variable
    const ratedMessage = localUpdatedMessages.find(
      (message) => message.id === messageId
    );
    if (ratedMessage) {
      Logger.debug(ratedMessage); // Make sure you're logging the right variable
      const sessionId = sessionStorage.getItem("uuid") || ""; // Ensure you get the session ID as needed
      await sendRating(ratedMessage, ratingType, messageId, sessionId);
    }
  };

  //user can scroll down the messages
  useEffect(() => {
    if (isScrolledToBottomBeforeUpdate) {
      endOfMessagesRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  }, [messages]);

  const isScrolledToBottomBeforeUpdate = () => {
    const { scrollTop, scrollHeight, clientHeight } =
      document.querySelector(".msger-chat");
    return scrollHeight - scrollTop === clientHeight;
  };

  return (
    <>
      <div className="chatbot-page">
        <Header />
        <div className="language-dropdown-container">
          <p className="language-label"> Select Language</p>
            <select
              value={language}
              onChange={(e) => onLanguageChange(e.target.value)}
              className="language-dropdown"
            >
              <option value="english">English</option>
              <option value="chinese">Chinese</option>
            </select>
          </div>

        <div className="style-button-containter">
          <div
            className={`toggle-container ${
              compactMode ? "mode-compact" : "mode-wide"
            }`}
          >
            <span className="label-left">W</span>
            <button
              className={`toggle-theme-btn ${compactMode ? "compact" : "wide"}`}
              onClick={toggleCompactMode}
            >
              {compactMode ? "" : ""}
            </button>
            <span className="label-right">C</span>
          </div>
        </div>

        <div className="body_chat">
          <section className={`msger ${compactMode ? "compact" : ""}`}>
            {/* <header className="msger-header"></header> */}

            <main className="msger-chat">
              {!hasAgreed && showAgreement && (
                <AgreementComponent onAgree={handleAgree} />
              )}

              {hasAgreed && sessionId && (
                <>
                  {/* submit rating */}
                  <MessageList
                    messages={messages}
                    onRating={handleRating}
                    endOfMessagesRef={endOfMessagesRef}
                    isExperiment={false}
                  />
                  {showSessionCompleteButton && (
                    <div
                      ref={endOfContentRef}
                      className="session-complete-container"
                    >
                      <button
                        className="session-complete-btn"
                        onClick={clearSessionMessages}
                      >
                        Start a new session
                      </button>

                      <button
                        className="session-action-btn"
                        onClick={handleContinueConversation}
                      >
                        Continue Conversation
                      </button>
                    </div>
                  )}
                </>
              )}
            </main>

            <MessageInputArea
              onMessageSubmit={handleMessageSubmit}
              onConversationEnd={requestSummarySurrogate}
              isAwaitingBotResponse={isAwaitingBotResponse || isLoading || isTyping}
              isDisabled={!hasAgreed || isLoading || isTyping}
            />
        
          </section>
        </div>
      </div>
      <LoadingModal isOpen={isLoading} />
    </>
  );
}

export default Chatbot;
