import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import TermsAndConditions from "./TermsContent";
import Header from "../components/Header";
import FrontPageFooter from "../components/FrontPageFooter";

const TermsConditionPage = () => {
  return (
    <div>
      <Header />
      <TermsAndConditions />
      <div className="privacy-footer">
        <FrontPageFooter />
      </div>
    </div>
  );
};

export default TermsConditionPage;
