import React, { useState } from "react";
import Header from "./Header";
import axios from "axios";
import CONFIG from "../config";

const AssignCredit = () => {
  const [selectedTab, setSelectedTab] = useState(10);
  const [userEmail, setUserEmail] = useState("");
  const [apiKey, setapiKey] = useState("");

  const handleTabClick = (amount: number) => {
    setSelectedTab(amount);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUserEmail(e.target.value); // Function to handle input change
  };

  const handleInputApi = (e: React.ChangeEvent<HTMLInputElement>) => {
    setapiKey(e.target.value); // Function to handle input change
  };
  const handleBuyCredits = async () => {
    const postData = new FormData();
    postData.append("user_email", JSON.stringify(userEmail));
    postData.append("credit_amount", selectedTab.toString());
    postData.append("api_key", JSON.stringify(apiKey));

    try {
      const buyCredits = await axios({
        method: "post",
        url: `${CONFIG.BASE_URL}/AssignCredits`,
        data: postData,
        headers: { "Content-Type": "multipart/form-data" },
      });
      // Check if the backend response indicates a successful credit assignment
      if (buyCredits.data.status === 101) {
        alert("Credit assigned successfully."); // Show an alert on success
      } else {
        // Handle other response statuses as needed
        alert("An error occurred. Please try again.");
      }
    } catch (error) {
      // Handle error, e.g., show an error message
    }
  };

  return (
    <div className="assign-credit">
      <Header />
      <div className="buy-credit-content">
        <h2>Select Credits</h2>

        <div className="api-containter">
          <input
            type="email"
            value={userEmail}
            onChange={handleInputChange}
            placeholder="Enter user's email"
            className="email-input"
          />

          <input
            value={apiKey}
            onChange={handleInputApi}
            placeholder="Enter API key"
            className="api-key"
          />
        </div>
        <div className="tab-buttons">
          <button
            className={selectedTab === 10 ? "active" : ""}
            onClick={() => handleTabClick(10)}
          >
            10
          </button>
          <button
            className={selectedTab === 50 ? "active" : ""}
            onClick={() => handleTabClick(50)}
          >
            50
          </button>
          <button
            className={selectedTab === 100 ? "active" : ""}
            onClick={() => handleTabClick(100)}
          >
            100
          </button>
          <button
            className={selectedTab === 200 ? "active" : ""}
            onClick={() => handleTabClick(200)}
          >
            200
          </button>
        </div>
        <button className="buy-button" onClick={handleBuyCredits}>
          Assign Credits
        </button>
      </div>
    </div>
  );
};

export default AssignCredit;
