import React, { useEffect, useState } from "react";
import Header from "./Header";
import SignUpForm from "./SignUpForm";
import "../css/loginstyle.scss";
import FrontPageFooter from "./FrontPageFooter";

interface LocationData {
  country?: string; 
}
const SignUpPage = () => {
  const [location, setLocation] = useState<LocationData>({});
  const [loadingLocation, setLoadingLocation] = useState(true);

  return (
   
      <div className="signup-header">
        <Header />
        <div className="login-page-container">
        <div className="form-and-chat">
          <SignUpForm />
        </div>
      </div>

      <div className="footer-container">
        <FrontPageFooter />
      </div>
    </div>
  );
};

export default SignUpPage;
