import React, { useState, useEffect } from "react";
import axios, { AxiosError } from "axios";
import { useNavigate } from "react-router-dom";
import { StatusCodes } from "http-status-codes";
import {usePsyfyClient} from "../hooks/psyfyClient";
import TermsAndConditions from "../content/TermsContent";
import Logger from "../utils/logger";


const SignUpForm = () => {
  const { checkUserIP, signupUser} = usePsyfyClient();
  const [clientEmail, setClientEmail] = useState("");
  const [clientPassword, setClientPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isOver18, setIsOver18] = useState(false);
  const [agreedToTerms, setAgreedToTerms] = useState(false);
  const [capacityReached, setCapacityReached] = useState(false);
  const [isRegionBlocked, setIsRegionBlocked] = useState(false);
  const user = "user";
  const [showTermsModal, setShowTermsModal] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [loading, setLoading] = useState(false); 

  const TermsModal: React.FC<TermsModalProps> = ({ onClose, onAgree }) => {
    return (
      <div className="modal">
        <div className="modal-content">
          <TermsAndConditions />
          <div className="modal-actions">
            <div className="button-container">
              <button className="agree-button" onClick={onAgree}>
                Agree
              </button>
              <button className="disagree-button" onClick={onClose}>
              Disagree
            </button>
            </div>
            
          </div>
        </div>
      </div>
    );
  };

  const LoadingPopup = () => (
    <div className="loading-backdrop">
    <div className="loading-popup">
      <div className="loading-content">
        <p>Please wait...</p>
      </div>
    </div>
    </div>
  );



  // Handle checkbox directly to manage Terms Modal visibility
  const handleTermsCheckboxChange = () => {
    setShowTermsModal(!showTermsModal);
  };

  const handleAgree = async () => {
    setAgreedToTerms(true);
    setShowTermsModal(false);
  };

  const handleDisagree = () => {
    setShowTermsModal(false);
    setAgreedToTerms(false);
  };

  const navigate = useNavigate();

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    // Reset previous error messages.
    setErrorMessage("");

    if (!isOver18) {
      setErrorMessage("You must confirm that you are over 18 years old.");
      return;
    }

    if (!agreedToTerms) {
      setErrorMessage("You must agree to the terms and conditions to sign up.");
      return;
    }

    setLoading(true);

    const postData = new FormData();
    postData.append("clientEmail", clientEmail);
    postData.append("pw1", clientPassword);
    postData.append("agreedToTerms", JSON.stringify(agreedToTerms));

    let data;

    setIsButtonDisabled(true); // Disable the button on form submit
    setTimeout(() => {
      setIsButtonDisabled(false); // Re-enable the button after 2 minutes
      setLoading(false);
    }, 50000); //50000



    try {

      const response = await signupUser(postData);    

      Logger.debug("this is response message", response.status)  

    
      //   if (!response.data) {
      //       throw new Error("No data received from the server. Check the server response format.");
      //   }
      const data = response.data;

     // setLoading(false);
      
    switch (response.status) {
      case 200:
          // Success case, data should have all required fields
          localStorage.setItem("userEmail", data.clientEmail);
          localStorage.setItem("access_token", data.access_token);
          navigate(capacityReached ? "/profile" : "/chatbot");
          break;
      case 400:
          setErrorMessage("Email validation failed. Please provide a deliverable email address.");
          break;
      case 403:
          setErrorMessage("User already exists. Please try logging in.");
          break;
      case 409:
          setErrorMessage("Wrong password format. Please follow the required format.");
          break;
      default:
          setErrorMessage("There was a problem with your signup attempt. Please check your email and try again.");
          break;
  }
} catch (error) {
  Logger.error("Error during signup:", error);
  if (axios.isAxiosError(error) && error.response) {
    // Handle Axios errors with a proper response from the server
    let message = "Error: ";
    if (error.response.data && error.response.data.message) {
      message += error.response.data.message;
    } else {
      message += "An unexpected server error occurred.";
    }
    message += " (Status: " + error.response.status + ")";
    setErrorMessage(message);
  } else {
    // Handling non-Axios errors or network errors where the response is not available
    if (error instanceof Error) {
      setErrorMessage("An unexpected error occurred: " + error.message);
    } else {
      setErrorMessage("An unexpected error occurred. Please try again later.");
    }
  }

}
};


  if (isRegionBlocked) {
    return (
      <div className="centered-wrapper">
        <div className="block-message">
          <p>{errorMessage}</p>
          <p>
            Contact <a href="mailto:psyfy@psyfy.ai">psyfy@psyfy.ai</a> if you
            want to try our service.
          </p>
        </div>
      </div>
    );
  }

  if (showTermsModal) {
    return <TermsModal onClose={handleDisagree} onAgree={handleAgree} />;
  }

  return (
    <div className="login-page">
       {loading && <LoadingPopup />}
       
      <div className="form">
        {capacityReached && ( // Conditionally render the waitlist message
          <p>
            We have currently reached our testing capacity. You have
            been placed on a waiting list and will be notified via email when a
            spot becomes available. Thank you for your patience.
          </p>
       
        )}
        <form className="login-form" onSubmit={handleSubmit}>
          <p
            style={{
              color: "#31304D",
              marginBottom: "30px",
              marginRight: "40px",
              fontSize: "16px",
            }}
          >
            Please ensure your password consists of a minimum of six characters
            including at least one numeric digit, one uppercase letter, and one
            special symbol.
          </p>
          <input
            type="text"
            placeholder="your email"
            value={clientEmail}
            onChange={(e) => setClientEmail(e.target.value)}
          />

          <input
            type="password"
            placeholder="password"
            value={clientPassword}
            onChange={(e) => setClientPassword(e.target.value)}
          />

          <div className="checkbox-container">
            <input
              className="checkbox-field"
              type="checkbox"
              checked={agreedToTerms}
              readOnly={showTermsModal}
              onChange={handleTermsCheckboxChange} 
            />
            <label className="checkbox-label">
              I agree to the Terms and Conditions
            </label>
          </div>

          <div className="checkbox-container">
            <input
              className="checkbox-field"
              type="checkbox"
              checked={isOver18}
              onChange={(e) => setIsOver18(e.target.checked)}
            />
            <label className="checkbox-label">
              I declare that I am over 18 years old
            </label>
          </div>

          <button type="submit" disabled={isButtonDisabled}>SignUp</button>

          <div>
            <p className="errorMessage">{errorMessage}</p>
          </div>
        </form>
      </div>
    </div>
  );
};

export default SignUpForm;
