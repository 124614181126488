import dotenv from "dotenv";
dotenv.config();

const CONFIG = {
  BACKUP_URL: "https://psyfy.pro",


  //BASE_URL: "https://aimia.site:2224",
  BASE_URL: "https://ophiuchi.ddns.net:2224", 






  GOOGLE_CLIENT_ID: process.env.REACT_APP_GOOGLE_CLIENT_ID,
  STRIPE_PUBLIC: process.env.REACT_APP_STRIPE_PUBLIC,
  
};
export default CONFIG;

