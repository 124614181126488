import React, { useState, useEffect, useRef } from "react";


function MessageInputArea({
  onMessageSubmit,
  onConversationEnd,
  isAwaitingBotResponse,
  isDisabled,
}) {
  const [message, setMessage] = useState("");
  const textAreaRef = useRef(); // Create a ref for the textarea

  useEffect(() => {
    // Focus the textarea when it becomes enabled (not awaiting response and not disabled)
    if (!isAwaitingBotResponse && !isDisabled && textAreaRef.current) {
      textAreaRef.current.focus();
    }
  }, [isAwaitingBotResponse, isDisabled]);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (message.trim() && !isAwaitingBotResponse) {
      onMessageSubmit(message);
      setMessage(""); // Clear the input after sending
    }
  };

  // handle conversation end without form submission
  const handleEndConversation = async (e) => {
    e.preventDefault();

    const summaryData = await onConversationEnd();
    console.log("Conversation Summary:", summaryData);
  };

  // Function to handle key presses in the textarea
  const handleKeyPress = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      handleSubmit(e);
    }
  };

  return (
    <form className="msger-inputarea" onSubmit={handleSubmit}>
      <textarea
        ref={textAreaRef}
        type="text"
        className="msger-input"
        id="user-message"
        placeholder="Enter your message... (Shift+Enter for a new line)"
        value={message}
        onKeyDown={handleKeyPress}
        onChange={(e) => setMessage(e.target.value)}
        name="user-message"
        disabled={isAwaitingBotResponse || isDisabled} // Disable input when awaiting bot response
      />
      <button
        type="submit"
        className="msger-send-btn"
        disabled={isAwaitingBotResponse || isDisabled||!message.trim()}
      >
        Send
      </button>
      {/* Use a `button` with `type="button"` for the End button to prevent form submission */}
      {/* <button
        type="button"
        className="msger-send-btn"
        onClick={handleEndConversation}
      >
        End
      </button> */}
    </form>
  );
}

export default MessageInputArea;
