import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import PrivacyPolicy from "./PrivacyContent";
import FrontPageFooter from "../components/FrontPageFooter";
import Header from "../components/Header";

const PrivacyPolicyPage = () => {
  return (
    <div>
      <Header />
      <PrivacyPolicy />
      <div className="privacy-footer">
        <FrontPageFooter />
      </div>
    </div>
  );
};

export default PrivacyPolicyPage;
