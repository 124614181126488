import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import {usePsyfyClient} from "../hooks/psyfyClient";
import Logger from "../utils/logger";
import GoogleLoginButton from "../hooks/LoginForm"
//import { GoogleLogin, GoogleLoginResponse, GoogleLoginResponseOffline } from 'react-google-login';

// LoginForm component
const LoginForm = () => {
  const {loginUser} = usePsyfyClient();
  const [clientEmail, setClientEmail] = useState("");
  const [clientPassword, setClientPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const navigate = useNavigate();



 
  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    try {
      const trimmedEmail = clientEmail.trim();
      const response = await loginUser(trimmedEmail, clientPassword);

      const data = response.data;

      if (data["auth"] === "1") {
        localStorage.setItem("access_token", data["access_token"]);
        localStorage.setItem("username", data["user_name"]);
        localStorage.setItem("userEmail", data["user_email"]);
        localStorage.setItem("data", JSON.stringify(data));
        localStorage.setItem("sessions", JSON.stringify(data["sessions"]));
        // Use navigate to go to /profile page
        navigate("/chatbot");
      } else if (data["status"] === 409) {
        setErrorMessage("Wrong password or you haven't registered yet");
      }
    } catch (error) {
      Logger.debug("authentication error", error);
      setErrorMessage("An error occurred while logging in.");
    }
  };

  return (
    <div className="login-page">
      <div className="form">
        <form className="login-form" onSubmit={handleSubmit}>
          <input
            type="text"
            placeholder="your email"
            value={clientEmail}
            onChange={(e) => setClientEmail(e.target.value)}
          />
          <input
            type="password"
            placeholder="password"
            value={clientPassword}
            onChange={(e) => setClientPassword(e.target.value)}
          />
          <button type="submit">login</button>
        
          {/* <GoogleLoginButton /> */}

          <p className="message">
            Not registered yet? <Link to="/signup">Create an account</Link>
            <br></br>
            <Link to="/pwReset" className="password-reset">
              {" "}
              I forgot my password
            </Link>
          </p>
          <div>
            <p className="errorMessage">{errorMessage}</p>
          </div>
        </form>
      </div>
    </div>
  );
};

export default LoginForm;
