import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import CONFIG from "../config";
import Header from "./Header";
import Logger from "../utils/logger";

const ResetPasswordPage = () => {
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [token, setToken] = useState("");
  const [validToken, setValidToken] = useState(false);
  const [message, setMessage] = useState("");
  const navigate = useNavigate();

  const { search } = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(search);
    const tokenParam = params.get("token");
    if (tokenParam) {
      setToken(tokenParam);
      validateToken(tokenParam);
    }
  }, [search]);

  const validateToken = async (token) => {
    try {
      const response = await axios.get(
        `${CONFIG.BASE_URL}/validate_token?token=${token}`
      );
      if (response.data.success) {
        setValidToken(true);
      } else {
        setMessage("The reset link has expired or is invalid.");
      }
    } catch (error) {
      Logger.error("Error validating token:", error);
      setMessage("There was an error validating your request.");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (newPassword !== confirmPassword) {
      Logger.error("password do not match");
      setMessage("Passwords do not match.");

      return;
    }

    try {
      const postData = new FormData();
      postData.append("token", JSON.stringify(token));
      postData.append("new_password", JSON.stringify(newPassword));

      const response = await axios.post(
        `${CONFIG.BASE_URL}/reset_password`,
        postData
      );

      if (response.data.success) {
        setMessage("Your password has been updated successfully.");
        alert("Your password has been updated successfully.");
        // Redirect to login page after a short delay
        setTimeout(() => {
          navigate("/login");
        }, 3000);
      } else {
        setMessage(response.data.message);
      }
    } catch (error) {
      Logger.error("Error resetting password:", error);
      setMessage("An error occurred. Please try again later.");
    }
  };

  return (
    <div>
      <Header />
      <div className="reset-password-container">
        <h2 className="page-title">Reset Password</h2>
        {validToken ? (
          <form onSubmit={handleSubmit} className="reset-password-form">
            <div>
              <label htmlFor="newPassword">New password:</label>
              <input
                type="password"
                id="newPassword"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                required
                className="reset-password-input"
              />
            </div>
            <div>
              <label htmlFor="confirmPassword">Confirm new password:</label>
              <input
                type="password"
                id="confirmPassword"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                required
                className="reset-password-input"
              />
            </div>
            <button type="submit" className="reset-password-button">
              Reset Password
            </button>
          </form>
        ) : (
          <p>
         
          </p>
        )}
        {/* Always render the message container, update content conditionally */}
        <p
          className={`message ${
            message.includes("do not match") ? "error" : ""
          }`}
        >
          {message}
        </p>
      </div>
    </div>
  );
};

export default ResetPasswordPage;
